import useSystem from 'coreSrc/base/systemManager/useSystem';

import styled from 'styled-components';

type NotFoundPageProps = {
  enableHomeBtn?: Boolean;
};
export default function NotFoundPage({ enableHomeBtn = true }: NotFoundPageProps) {
  const sys = useSystem();

  function onHomeClick() {
    if (!sys.detector.isMobile) return sys.router.go('pc');
    sys.router.go('index');
  }

  return (
    <SD_Container>
      <SD_Container2>
        <img src={'/icons/svgFiles/page/404Page.svg'} />

        <SD_Text001>페이지를 찾을 수 없어요.</SD_Text001>
        <SD_Text002>
          요청하신 페이지가 사라졌거나,
          <br />
          잘못된 경로를 이용하셨어요.
        </SD_Text002>
      </SD_Container2>

      {enableHomeBtn && <SD_Home onClick={onHomeClick}>홈으로 이동</SD_Home>}
    </SD_Container>
  );
}
const SD_Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 20px 36px 20px;
`;
const SD_Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const SD_Text001 = styled.div`
  margin-top: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.6px;
  color: #333a46;
`;
const SD_Text002 = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.4px;
  color: #6d7787;
`;
const SD_Home = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: #3b67db;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #ffffff;
`;
